import { cn } from "~/utils/cn";

export default function Logo({
  noText = false,
  className
}: {
  noText?: boolean;
  className?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="76.19 447.5 927.71 185"
      width="100%"
      height="100%"
      className={cn("text-acc", className)}
    >
      <g>
        <polygon
          fill="currentColor"
          points="295.28 452.78 273.98 482.86 230.14 508.74 237.26 551.16 199.73 576.78 162.21 551.16 169.33 508.74 125.49 482.86 104.18 452.78 180.27 476.83 172.02 538.34 199.73 549.14 227.45 538.34 219.2 476.83 295.28 452.78"
        ></polygon>
        <polygon
          fill="currentColor"
          points="199.73 576.78 177.18 589.21 125.43 551.16 97.34 450.31 90.47 447.5 76.19 532.49 199.73 632.5 323.26 532.49 308.98 447.5 302.13 450.31 274.02 551.16 222.29 589.21 199.73 576.78"
        ></polygon>
      </g>

      {noText ? null : (
        <g>
          <path
            fill="currentColor"
            d="M876.29,482.88v106.33h127.61v-106.33h-127.61Zm106.35,85.05h-85.08v-63.79h85.08v63.79Z"
          ></path>
          <polygon
            fill="currentColor"
            points="855.01 567.94 855.01 589.21 727.39 589.21 727.39 525.41 855.01 525.41 855.01 546.68 748.66 546.68 748.66 567.94 855.01 567.94"
          ></polygon>
          <polygon
            fill="currentColor"
            points="706.12 567.94 706.12 589.21 578.5 589.21 578.5 482.88 599.77 482.88 599.77 567.94 706.12 567.94"
          ></polygon>
          <rect
            fill="currentColor"
            x="387.07"
            y="482.88"
            width="21.27"
            height="106.33"
          ></rect>
          <rect
            fill="currentColor"
            x="429.61"
            y="525.42"
            width="21.27"
            height="63.79"
          ></rect>
          <polygon
            fill="currentColor"
            points="557.23 482.88 557.23 589.21 526.45 589.21 514.69 575.97 450.88 504.15 429.61 504.15 429.61 482.88 460.44 482.88 479.34 504.15 535.96 567.88 535.96 482.88 557.23 482.88"
          ></polygon>
          <rect
            fill="currentColor"
            x="780.57"
            y="429.71"
            width="21.27"
            height="127.62"
            transform="translate(1284.72 -297.69) rotate(90)"
          ></rect>
        </g>
      )}
    </svg>
  );
}

